import React, { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import { useParams, useSearchParams } from 'react-router-dom'
import EnemyCard from 'components/Enemies/EnemyCard'
import ImpactCard from './ImpactCard'
import ImpactEditor from './ImpactEditor'
import CommentsSection from 'components/Comments/CommentsSection'
import { CircularProgress } from '@mui/joy'

import { User, Impact, Enemy, Scope } from 'util/constants'

interface Props {
    currentUser: User
}

const ImpactProfile = ({ currentUser }: Props) => {
    const { id } = useParams<{ id: string }>()
    const [impact, setImpact] = useState<Impact | null>(null)
    const [relatedEnemy, setRelatedEnemy] = useState<Enemy | null>(null)
    const [error, setError] = useState<string>('')
    const [editMode, setEditMode] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [scope, setScope] = useState<Scope>(null)
    const [referenceLink, setReferenceLink] = useState<string>('')
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const fetchImpact = async () => {
            try {
                const response = await api.get<Impact>(`/api/impacts/${id}`)
                setImpact(response.data)
                setDescription(response.data.description)
                setScope(response.data.scope as Scope)
                setReferenceLink(response.data.reference_link)
                if (response.data.enemy_id) {
                    const enemyResponse = await api.get<Enemy>(
                        `/api/enemies/id/${response.data.enemy_id}`
                    )
                    setRelatedEnemy(enemyResponse.data)
                }
                if (searchParams.get('edit') === 'true') {
                    setEditMode(true)
                }
            } catch (err) {
                setError(
                    'Failed to load impact profile. Please try again later.'
                )
            }
        }

        fetchImpact()
    }, [id, searchParams])

    if (error) {
        return <div>{error}</div>
    }

    if (!impact) {
        return (
            <div>
                <CircularProgress size="lg" />
            </div>
        )
    }

    return (
        <div className="impact-profile">
            {impact && (
                <>
                    {editMode ? (
                        <ImpactEditor
                            impact={impact}
                            currentUser={currentUser}
                            description={description}
                            scope={scope as Scope}
                            referenceLink={referenceLink}
                            editMode={true}
                        />
                    ) : (
                        <ImpactCard
                            impact={impact}
                            truncateDescription={true}
                            isImpactProfilePage={true}
                            currentUser={currentUser}
                        />
                    )}
                </>
            )}
            <CommentsSection
                commentableId={impact.id}
                commentableType="impact"
                currentUser={currentUser}
            />
            <div className="related-enemies-container">
                <h2>Related Enemy</h2>
                {relatedEnemy && (
                    <EnemyCard
                        enemy={relatedEnemy}
                        showMeta={true}
                        showActions={true}
                        onVote={() => {}}
                        truncateDescription={true}
                        currentUser={currentUser}
                    />
                )}
            </div>
        </div>
    )
}

export default ImpactProfile
