import React, { useState, useEffect } from 'react'
import { Box, Typography, CircularProgress, Grid, Button } from '@mui/joy'
import { Link } from 'react-router-dom'
import api from 'config/axiosConfig'
import EnemyCard from 'components/Enemies/EnemyCard'
import ImpactCard from 'components/Impacts/ImpactCard'
import SolutionCard from 'components/Solutions/SolutionCard'
import { User, SavedEntity } from 'util/constants'

interface Props {
    username: string
    currentUser: User
}

const UserProfileSaved = ({ username, currentUser }: Props) => {
    const [savedEntities, setSavedEntities] = useState<SavedEntity[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)

    const fetchUserSavedEntities = async () => {
        try {
            const response = await api.get(`/api/saves`)
            setSavedEntities(response.data)
            setLoading(false)
        } catch (err) {
            setError('Failed to load saved entities')
            setLoading(false)
        }
    }

    useEffect(() => {
        if (username === currentUser?.username) {
            fetchUserSavedEntities()
        } else {
            setLoading(false)
        }
    }, [username, currentUser?.username])

    const renderEntityCard = (entity: SavedEntity) => {
        switch (entity.entity_type) {
            case 'enemy':
                return (
                    <EnemyCard
                        enemy={entity.attributes}
                        showMeta={true}
                        showActions={true}
                        truncateDescription={true}
                        isEnemyProfilePage={false}
                        currentUser={currentUser}
                    />
                )
            case 'impact':
                return (
                    <ImpactCard
                        impact={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isImpactProfilePage={false}
                        currentUser={currentUser}
                    />
                )
            case 'solution':
                return (
                    <SolutionCard
                        solution={entity.attributes}
                        showMeta={true}
                        truncateDescription={true}
                        isSolutionProfilePage={false}
                        currentUser={currentUser}
                    />
                )
            default:
                return null
        }
    }

    if (username !== currentUser.username) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    py: 4,
                }}
            >
                <Typography level="h4" color="danger">
                    You do not have permission to access this page.
                </Typography>
                <Button
                    component={Link}
                    to={`/user/${currentUser.username}/saved`}
                    variant="solid"
                    color="primary"
                >
                    View Your Saved Items
                </Button>
            </Box>
        )
    }

    if (loading) {
        return <CircularProgress />
    }

    if (error) {
        return <Typography color="danger">{error}</Typography>
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pb: 4 }}>
            <Grid container spacing={2}>
                {savedEntities.map((entity) => (
                    <Grid xs={12} key={entity.eid}>
                        {renderEntityCard(entity)}
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default UserProfileSaved
