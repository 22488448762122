export interface User {
    id: number
    username: string
    login_name: string
    display_name: string
    email: string
    password_weak: null
    password_last_set_ts: string
    created_ts: string
    updated_ts: string
    is_active: boolean
    auth_type: string
    last_login_ts: string
    deactivated_ts: string
    deleted_ts: string
    de_reason: null
    attributes: {
        is_admin: boolean
    }
}

// Placeholder fixture while to bypass Auth
// Placeholder fixture while to bypass Auth
export const defaultUser: User = {
    id: 1,
    username: 'cowsgomoohaha',
    login_name: '111536752333522547357',
    display_name: 'Moo Rahman',
    email: 'mootabsites@gmail.com',
    password_weak: null,
    password_last_set_ts: '',
    created_ts: '2024-08-09T19:04:26.468Z',
    updated_ts: '',
    is_active: true,
    auth_type: 'google',
    last_login_ts: '2024-08-28T18:47:24.270Z',
    deactivated_ts: '',
    deleted_ts: '',
    de_reason: null,
    attributes: {
        is_admin: true
    }
}

// MOO: Did I do this right? A suggestion just returns an Enemy. TO-DO: Ask Moo what a Suggestion looks like from: /api/enemies?search=${searchQuery}
export type Suggestion = Enemy /*{
    id: string
    name: string
    placeholder: string
}*/

// TO-DO: update against future API spec
export interface Entity {
    id: string | number
    entity_type: EntityType
    eid: string | null | undefined
    created_ts: string
    updated_ts: string
    vote_count: number
    attributes: any
}

// TO-DO: update against future API spec
export interface Enemy {
    id: number
    name: string
    wikipedia_link: string
    created_ts: string
    att_hash: string
    enemy_id: number
    scope: string
    status: string
    description: string
    inactive_ts: string
    is_latest: true
    user_id: number
    updated_ts: string
    upvotes: number
    downvotes: number
    vote_count: number
    user_display_name: string
    username: string
    reference_link: string
    user_vote: string
    rel_type: string,
    properties: any,
    attributes: {
        type: string | null | undefined,
        status: string | null | undefined
    } | null
}

// TO-DO: update against future API spec
export interface Solution {
    id: number
    name: string
    description: string
    reference_link: string
    user_id: number
    username: string
    created_ts: string
    updated_ts: string
    is_active: boolean
    upvotes: number
    downvotes: number
    vote_count: number
    rel_type: string
}

export interface Impact {
    id: number
    name: string
    reference_link: string
    att_hash: string
    impact_id: number
    scope: string
    created_ts: string
    inactive_ts: null
    is_latest: boolean
    user_id: number
    updated_ts: string
    display_name: string
    enemy_id: number
    rel_type: string
    rel_description: null
    upvotes: number
    downvotes: number
    vote_count: number
    title: string
    description: string
    username: string
    user_vote: null
}

export interface Comment {
    id: string
    name: string | null | undefined
    commentableId: number
    user_id: number
    parent_id: string | null
    rootId: string | null
    content: string
    created_ts: string
    updated_ts: string
    commentableType: EntityType
    eid: string | null | undefined
    username: string
    upvotes: number
    downvotes: number
    vote_count: number
    replies: Comment[]
}

export interface SavedEntity {
    eid: string | null | undefined
    entity_type: EntityType
    attributes: any
    created_ts: string
    updated_ts: string
}

export interface UsernameValidationResponse {
    isValid: boolean;
    message: string;
}

export type Sort = 'top' | 'new' | 'bottom' | 'old' | undefined
export type SortField = 'vote_count' | 'created_ts' | 'updated_ts'
export type SortOrder = 'desc' | 'asc'
export type Scope = 'All' | 'Local' | 'National' | 'Global' | null

export type Status =
    | 'All'
    | 'Emerging'
    | 'Established'
    | 'Eroding'
    | 'Eliminated'
    | null

export type EntityType = 'impact' | 'enemy' | 'solution' | 'comment'

export type EnemyType =
    | 'All'
    | 'Person'
    | 'Corporation'
    | 'Organization'
    | 'Regulation'
    | 'Concept'
    | 'Environment'
    | 'Territory'
    | 'Other'
    | null
