import React, { useState, useEffect } from 'react'
import api from 'config/axiosConfig'

import { Enemy } from 'util/constants'

interface Props {
    solutionId: string
    onEnemyAdded: (value: Enemy) => void
}

const AddEnemyToSolution = ({ solutionId, onEnemyAdded }: Props) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResults, setSearchResults] = useState<Enemy[]>()
    const [selectedEnemy, setSelectedEnemy] = useState<Enemy | null>()
    const [error, setError] = useState('')

    useEffect(() => {
        if (searchQuery.length > 0) {
            const fetchEnemies = async () => {
                try {
                    const response = await api.get(
                        `/api/enemies?search=${searchQuery}`
                    )
                    setSearchResults(response.data)
                } catch (error) {
                    console.error('Error fetching enemies:', error)
                }
            }
            fetchEnemies()
        } else {
            setSearchResults([])
        }
    }, [searchQuery])

    const handleAddEnemy = async () => {
        if (!selectedEnemy) {
            setError('Please select an enemy.')
            return
        }

        try {
            await api.put(
                `/api/solutions/link/${solutionId}/${selectedEnemy.id}`
            )
            onEnemyAdded(selectedEnemy)
            setSearchQuery('')
            setSearchResults([])
            setSelectedEnemy(null)
            setError('')
        } catch (error) {
            console.error('Error adding enemy to solution:', error)
            setError('Failed to add enemy to solution. Please try again.')
        }
    }

    return (
        <div className="add-enemy-to-solution">
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search enemies..."
                className="search-input"
            />
            <button className="action-button" onClick={handleAddEnemy}>
                Relate Enemy
            </button>
            {error && <div className="error-message">{error}</div>}
            <ul className="search-results">
                {searchResults?.map((enemy) => (
                    <li
                        key={enemy.id}
                        className={`search-result ${selectedEnemy && selectedEnemy.id === enemy.id ? 'selected' : ''}`}
                        onClick={() => setSelectedEnemy(enemy)}
                    >
                        {enemy.name}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default AddEnemyToSolution
