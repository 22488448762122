import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Image from 'rc-image';
import RenderComments from './RenderComments';
import CommentInput from './CommentInput';
import './Comment.css';
import EntityActions from '../Entities/EntityActions';
import Report from 'components/Entities/Report'
import { Comment as CommentData, User } from 'util/constants';
import { Menu, MenuItem } from '@mui/joy'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  ChevronDown,
  ChevronUp,
  MessageSquare
} from 'lucide-react';
dayjs.extend(relativeTime);

interface CommentsProps extends CommentData {
    commentableType: CommentData['commentableType'];
    commentableId: CommentData['commentableId'];
    id: CommentData['id'];
    content: CommentData['content'];
    replies: CommentData['replies'];
    currentUser: User;
    depth: number;
    maxDepth: number;
    isAllCollapsed: boolean;
    onAddReply: (parent_id: string, content: string) => void;
    onUpdateComment: (id: string, content: string) => void;
    onDeleteComment: (id: string) => void;
  }
  
  const Comment: React.FC<CommentsProps> = ({ 
      id, 
      username, 
      user_id, 
      created_ts, 
      content, 
      replies, 
      commentableType, 
      commentableId, 
      upvotes, 
      downvotes, 
      vote_count,
      parent_id, 
      rootId, 
      updated_ts, 
      depth = 0, 
      maxDepth = 3, 
      isAllCollapsed = false, 
      currentUser, 
      onAddReply, 
      onUpdateComment, 
      onDeleteComment 
    }) => {
    const [isAuthor, setIsAuthor] = useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isReplying, setIsReplying] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const moreIconRef = useRef<HTMLDivElement>(null)
    const [isReportModalOpen, setIsReportModalOpen] = useState(false)
    const reportModalRef = useRef<HTMLDivElement>(null)
    const hasReplies = replies && replies.length > 0;
    const entity = {
      id,
      name: null,
      eid: null,
      username,
      user_id,
      upvotes,
      downvotes,
      vote_count,
      content,
      commentableType,
      commentableId,
      created_ts,
      replies,
      parent_id,
      rootId,
      updated_ts
    }

  useEffect(() => {
    setIsAuthor(currentUser?.username === username);
  }, [currentUser, username]);

  useEffect(() => {
    setIsCollapsed(depth >= maxDepth || isAllCollapsed);
  }, [depth, maxDepth, isAllCollapsed]);

  const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (anchorEl) {
          setAnchorEl(null) // Close the menu if it's already open
      } else {
          setAnchorEl(event.currentTarget)
      }
  }

  const handleMenuClose = () => {
      setAnchorEl(null)
      setIsReportModalOpen(false)
  }

  // Close the menu if clicked outside
  useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
          const element = event.target as HTMLElement
          if (
              anchorEl &&
              !moreIconRef.current?.contains(element) &&
              !reportModalRef.current?.contains(element)
          ) {
              handleMenuClose()
          }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
          document.removeEventListener('mousedown', handleClickOutside)
      }
  }, [anchorEl])

  const handleAddReply = (replyContent: string) => {
    if (id) {
      onAddReply(id, replyContent);
      setIsCollapsed(false);
      setIsReplying(false);
    }
  };

  const handleUpdateComment = (updatedContent: string) => {
    if (id) {
      onUpdateComment(id, updatedContent);
      setIsCollapsed(false);
      setIsEditing(false);
    }
  };

  const handleDeleteComment = () => {
    if (id) {
      if (window.confirm('Are you sure you want to delete this comment?')) {
        onDeleteComment(id);
      }
    }
  };

  return (
    <div className={`relative mb-2 ${depth > 0 ? 'border-l pl-4' : ''}`}>
        <article>
      <div className="flex space-x-2">
        <div className="h-8 w-8 flex-shrink-0 rounded-full bg-gray-300">
        {hasReplies && (
          <span className="absolute bottom-2 left-4 top-10 w-[1px] bg-gray-300" >
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="absolute text-gray-500 hover:text-gray-700"
          >
            {isCollapsed ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
          </button>
          </span>
        )}
        <Image
          src={`/eoe_earth_337.png`}
          alt={username}
          width={32}
          height={32}
          className="rounded-full"
        />
      </div>

      <div className="flex-grow">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-sm flex items-center space-x-2">
            {username !== '[deleted]' ? (         
              <Link
                to={`/user/${username}`}
                className="font-semibold text-gray-950 dark:text-gray-300 ml-2"
              >
                {username}
              </Link>) : (
              <span className="font-semibold text-gray-950 dark:text-gray-300 ml-2">
                {username}
              </span>
              )}
              <span className="text-gray-400">• {dayjs(created_ts).fromNow()}</span>
            </div>
          </div>            
        </div>
        {isEditing ? (
          <CommentInput
            onSubmit={handleUpdateComment}
            onCancel={() => setIsEditing(false)}
            placeholder="Edit your comment"
            buttonText="Update"
            initialContent={content}
          />
        ) : (
          <>
          <p className="mx-2 my-2 text-sm">{content}</p>
          <div className="flex items-center flex-row">
          <EntityActions
              entity={entity}
              entityType="comment"
              currentUser={currentUser}
          /> 
          <button
            className="rounded-2xl flex mx-2 mt-2 md:mt-0 space-x-1 flex-row items-center hover:bg-gray-300"
            onClick={() => setIsReplying(!isReplying)}
          >
            <MessageSquare size={20} />
            <span className="text-xs">Reply</span>
          </button><div className="icon-more text-xl mt-0 md:mt-0">
                <button
                    className="size-9 rounded-3xl justify-center items-center mt-0.5 md:mb-1 hover:bg-gray-300 transition-colors"
                    onClick={handleMenuToggle}
                >
                    <MoreHorizOutlinedIcon />
                </button>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                ref={moreIconRef}
            >
                {isAuthor && currentUser?.id === user_id && (
                    <>
                    <MenuItem onClick={() => setIsEditing(!isEditing)}>
                        <EditOutlinedIcon />
                    </MenuItem>
                    <MenuItem onClick={handleDeleteComment}>
                        <DeleteOutlineOutlinedIcon />
                    </MenuItem>
                    </>
                )}
                <Report
                    entityId={id}
                    entityType="comment"
                    currentUser={currentUser}
                    isOpen={isReportModalOpen}
                    setIsOpen={setIsReportModalOpen}
                    modalRef={reportModalRef}
                />
            </Menu>
          </div>
          </>
        )}
        {isReplying && (
          <CommentInput
            onSubmit={handleAddReply}
            onCancel={() => setIsReplying(false)}
            placeholder="Add your reply"
            buttonText="Reply"
          />
        )}
      {hasReplies && !isCollapsed && (
        <RenderComments
          comments={replies}
          depth={depth + 1}
          maxDepth={maxDepth}
          isAllCollapsed={isAllCollapsed}
          onAddReply={onAddReply}
          onUpdateComment={onUpdateComment}
          onDeleteComment={onDeleteComment}
          currentUser={currentUser}
          commentableType={commentableType}
          commentableId={commentableId}
        />
      )}
    </div>
    </div>
    </article>
    </div>
  )
};

export default Comment;