import React, { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import AddImpact from './AddImpact'
import ImpactCard from './ImpactCard'
import {
    CircularProgress,
    Box,
    Button,
    Option,
    Select,
    Typography,
} from '@mui/joy'

import {
    User,
    Enemy,
    Impact,
    Scope,
    SortField,
    SortOrder,
} from 'util/constants'

interface Props {
    currentUser: User
    enemy: Enemy
    setShowAuthModal: (show: boolean) => void
}

const ImpactsSection = ({ currentUser, enemy, setShowAuthModal }: Props) => {
    const [impacts, setImpacts] = useState<Impact[]>([])
    const [error, setError] = useState<string>('')
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [scopeFilter, setScopeFilter] = useState<Scope>('All')
    const [impactTypeFilter, setImpactTypeFilter] = useState<
        'All' | 'impact_negative' | 'impact_positive'
    >('All')
    const [sortField, setSortField] = useState<SortField>('updated_ts')
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc')

    useEffect(() => {
        const fetchImpacts = async () => {
            try {
                const response = await api.get<Impact[]>(
                    `/api/impacts/enemies/${enemy.id}`
                )
                setImpacts(response.data)
            } catch (err) {
                setError('Failed to load impacts. Please try again later.')
            }
        }

        if (enemy) {
            fetchImpacts()
        }
    }, [enemy])

    const handleImpactAdded = async () => {
        // Re-fetch impacts after an impact is added
        const response = await api.get<Impact[]>(
            `/api/impacts/enemies/${enemy.id}`
        )
        setImpacts(response.data)
    }

    const handleScopeChange = (
        _event: React.SyntheticEvent | null,
        newValue: Scope | null
    ) => {
        if (newValue) setScopeFilter(newValue)
    }

    const handleImpactTypeChange = (
        _event: React.SyntheticEvent | null,
        newValue: 'All' | 'impact_negative' | 'impact_positive' | null
    ) => {
        if (newValue) setImpactTypeFilter(newValue)
    }

    const handleSortChange = (field: SortField) => {
        const order: SortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
        setSortField(field)
        setSortOrder(order)
    }

    const handleAddImpactButtonClick = () => {
        if (!currentUser) {
            setShowAuthModal(true)
        } else if (openModal) {
            setOpenModal(false)
        } else {
            setOpenModal(true)
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const filteredImpacts = impacts.filter((impact) => {
        const matchesScope =
            scopeFilter === 'All' || impact.scope === scopeFilter
        const matchesImpactType =
            impactTypeFilter === 'All' || impact.rel_type === impactTypeFilter
        return matchesScope && matchesImpactType
    })

    const sortedImpacts = filteredImpacts.sort((a, b) => {
        if (sortOrder === 'asc') {
            return (
                new Date(a[sortField]).getTime() -
                new Date(b[sortField]).getTime()
            )
        } else {
            return (
                new Date(b[sortField]).getTime() -
                new Date(a[sortField]).getTime()
            )
        }
    })

    if (error) {
        return <div>{error}</div>
    }

    if (!enemy) {
        return (
            <div>
                <CircularProgress size="lg" />
            </div>
        )
    }

    if (!impacts) {
        return (
            <div>
                <CircularProgress size="lg" sx={{ justifyContent: 'center' }} />
            </div>
        )
    }

    return (
        <Box>
            {openModal && (
                <AddImpact
                    enemyId={enemy.id}
                    onImpactAdded={handleImpactAdded}
                    currentUser={currentUser}
                    setShowAuthModal={setShowAuthModal}
                    openModal={handleAddImpactButtonClick}
                    onClose={handleCloseModal}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 1,
                }}
            >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Select
                        value={scopeFilter}
                        onChange={handleScopeChange}
                        size="sm"
                    >
                        <Option value="All">Scope</Option>
                        <Option value="Local">Local</Option>
                        <Option value="National">National</Option>
                        <Option value="Global">Global</Option>
                    </Select>
                    <Select
                        value={impactTypeFilter}
                        onChange={handleImpactTypeChange}
                        size="sm"
                    >
                        <Option value="All">Type</Option>
                        <Option value="impact_negative">Negative</Option>
                        <Option value="impact_positive">Positive</Option>
                    </Select>
                    <Button
                        size="sm"
                        variant="plain"
                        onClick={() => handleSortChange('updated_ts')}
                    >
                        Updated {sortOrder === 'asc' ? '▲' : '▼'}
                    </Button>
                </Box>
                <Button variant="outlined" onClick={handleAddImpactButtonClick}>
                    Add Impact
                </Button>
            </Box>
            {impacts.length === 0 ? (
                <Typography sx={{ mt: 2 }}>
                    No impacts exist for this enemy.
                </Typography>
            ) : (
                sortedImpacts.map((impact) => (
                    <ImpactCard
                        key={impact.id}
                        impact={impact}
                        truncateDescription={true}
                        currentUser={currentUser}
                    />
                ))
            )}
        </Box>
    )
}

export default ImpactsSection
