import React, { useState } from 'react'
import './AuthModal.css'
import GoogleSignin from './GoogleSignin'
import api from 'config/axiosConfig'
import UsernameModal from 'components/User/UsernameModal'

const AuthModal = ({ show, onClose }) => {
    const [authError, setAuthError] = useState('')
    const [currentUser, setCurrentUser] = useState(null)
    const [showUsernameModal, setShowUsernameModal] = useState(false)

    const handleCredentialResponse = async (response) => {
        try {
            const { credential } = response
            // Send the credential to the backend for verification
            const result = await api.post('/auth/google/callback', {
                token: credential,
            })

            if (result.status === 200) {
                // Update user state or perform any actions needed after successful login
                window.location.reload() // Reload the page to reflect the authentication state
            } else if (result.status === 201) {
                const response = await api.get('/api/user')
                setCurrentUser(response.data)
                // Show username modal for new users
                setShowUsernameModal(true)
            } else {
                setAuthError('Failed to authenticate. Please try again.')
            }
        } catch (error) {
            console.error('Error handling credential response:', error)
            setAuthError('Failed to authenticate. Please try again.')
        }
    }

    if (!show) {
        return null
    }

    return (
        <div className="auth-modal">
            <div className="auth-modal-content">
                <span className="close" open onClick={onClose}>
                    &times;
                </span>
                <h2>Join the Fight</h2>
                {authError && <div className="auth-error">{authError}</div>}
                <GoogleSignin
                    handleCredentialResponse={handleCredentialResponse}
                />
                <footer className="auth-modal-footer">
                    <p>
                        <a href="/">Return to Enemies of Earth</a>
                    </p>
                </footer>
            </div>
            {showUsernameModal && (
                <UsernameModal onClose={onClose} currentUser={currentUser} />
            )}
        </div>
    )
}

export default AuthModal
