import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import eoeLogo from 'assets/eoe_logo_nobg.png'
import api from 'config/axiosConfig'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import AddEnemy from 'components/Enemies/AddEnemy'
import { ReactComponent as PlusIcon } from 'assets/icon-plus.svg'
import { User, Suggestion } from 'util/constants'

interface Props {
    currentUser: User
    showAuthModal: (value: boolean) => void
}

const Header = ({ currentUser, showAuthModal }: Props) => {
    const navigate = useNavigate()
    const [searchQuery, setSearchQuery] = useState('')
    const [suggestions, setSuggestions] = useState<Suggestion[]>([])
    const [anchorElUser, setAnchorElUser] = useState<HTMLButtonElement | null>()
    const [openAddEnemyModal, setOpenAddEnemyModal] = useState(false)

    const settings = [
        {
            label: (
                <>
                    Profile
                    <br />
                    {currentUser?.username}
                </>
            ),
            icon: (
                <Avatar alt="Profile" sx={{ width: 24, height: 24, mr: 0.7 }} />
            ),
            link: `/user/${currentUser?.username}`,
        },
        {
            label: 'Settings',
            icon: (
                <SettingsIcon
                    fontSize="large"
                    sx={{ color: '#555555', mr: 1 }}
                />
            ),
            link: '/settings/',
        },
        {
            label: 'Logout',
            icon: (
                <LogoutIcon fontSize="large" sx={{ color: '#555555', mr: 1 }} />
            ),
            link: '/logout',
        },
    ]

    useEffect(() => {
        if (searchQuery.length > 0) {
            const fetchSuggestions = async () => {
                try {
                    const response = await api.get(
                        `/api/enemies?search=${searchQuery}`
                    )
                    setSuggestions(response.data)
                } catch (error) {
                    console.error('Error fetching suggestions:', error)
                }
            }
            fetchSuggestions()
        } else {
            setSuggestions([])
        }
    }, [searchQuery])

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value)
    }

    const handleAddEnemyButtonClick = () => {
        if (!currentUser) {
            showAuthModal(true)
        } else if (openAddEnemyModal) {
            setOpenAddEnemyModal(false)
        } else {
            setOpenAddEnemyModal(true)
        }
    }

    const handleLoginButtonClick = () => {
        if (!currentUser) {
            showAuthModal(true)
        } else {
            navigate('/')
        }
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <AppBar
            position="sticky"
            variant="outlined"
            elevation={0}
            sx={{
                borderBottom: '0.1rem solid #c8c8c8',
                '--AppBar-background': 'white',
                '--AppBar-color': 'neutral',
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters className="flex">
                    <a href="/">
                        <img
                            src={eoeLogo}
                            alt="Enemies of Earth Logo"
                            className="logo size-10 mr-2"
                        />
                    </a>
                    <div className="search-container px-2 grow flex justify-center">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search enemies..."
                            className="search-input bg-gray-200 w-full px-4 py-3 rounded-3xl text-sm max-w-screen-sm"
                        />
                        {suggestions.length > 0 && (
                            <ul className="suggestions-list">
                                {suggestions.map((suggestion) => (
                                    <li key={suggestion.id}>
                                        <Link
                                            to={`/enemy/${encodeURIComponent(suggestion.name.replace(/ /g, '_'))}`}
                                        >
                                            {suggestion.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {currentUser ? (
                        <>
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: 10, xs: 'block' },
                                }}
                            >
                                <button
                                    className="rounded-3xl px-4 py-3 mx-2 flex justify-center items-center gap-2 text-sm font-semibold hover:bg-gray-200 cursor-pointer transition-colors"
                                    onClick={handleAddEnemyButtonClick}
                                >
                                    <PlusIcon />
                                    <span>Enemy</span>
                                </button>
                                {openAddEnemyModal && (
                                    <AddEnemy
                                        currentUser={currentUser}
                                        open={openAddEnemyModal}
                                        onClose={() =>
                                            setOpenAddEnemyModal(false)
                                        }
                                    />
                                )}
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar alt="User" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => handleCloseUserMenu()}
                                >
                                    {settings.map((userButton) => (
                                        <MenuItem
                                            key={`${userButton.label}`}
                                            component={Link}
                                            to={userButton.link}
                                            onClick={handleCloseUserMenu}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {userButton.icon}
                                                <Typography
                                                    textAlign="left"
                                                    sx={{ ml: 1 }}
                                                >
                                                    {userButton.label}
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </>
                    ) : (
                        <button
                            className="rounded-3xl px-4 py-3 m-2 text-sm hover:bg-gray-500 hover:text-gray-100 cursor-pointer transition-colors"
                            onClick={handleLoginButtonClick}
                        >
                            Login
                        </button>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header
