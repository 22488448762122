import React, { useEffect, useState } from 'react'
import api from 'config/axiosConfig'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import EnemyCard from '../EnemyCard'
import { Box, Container, CircularProgress } from '@mui/joy'
import EnemyProfileTabs from './EnemyProfileTabs'

import { User } from 'util/constants'

interface Props {
    currentUser: User
    setShowAuthModal: (value: boolean) => void
}

const EnemyProfile = ({ currentUser, setShowAuthModal }: Props) => {
    const { name } = useParams()
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [enemy, setEnemy] = useState(null)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchEnemy = async () => {
            try {
                const response = await api.get(`/api/enemies/name/${name}`)
                const enemyData = response.data
                const params = new URLSearchParams(location.search)
                if (params.get('upvoted') === 'true') {
                    enemyData.user_vote = 'user_upvote'
                }
                setEnemy(enemyData)
            } catch (err) {
                setError(
                    'Failed to load enemy profile. Please try again later.'
                )
            }
        }

        fetchEnemy()
    }, [name, location.search, searchParams])

    if (error) {
        return <div>{error}</div>
    }

    if (!enemy) {
        return (
            <div>
                <CircularProgress size="lg" />
            </div>
        )
    }

    return (
        <Box>
            <Container>
                {enemy && (
                    <EnemyCard
                        enemy={enemy}
                        showMeta={true}
                        truncateDescription={true}
                        isEnemyProfilePage={true}
                        currentUser={currentUser}
                    />                    
                )}
            </Container>
            <EnemyProfileTabs
                enemy={enemy}
                currentUser={currentUser}
                setShowAuthModal={setShowAuthModal}
            />
        </Box>
    )
}

export default EnemyProfile
