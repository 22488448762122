import React, { useState, useEffect } from 'react'
import { Tabs, Tab, TabList, TabPanel, Box, Sheet, Container } from '@mui/joy'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import AdminReports from './AdminReports'
import { User } from 'util/constants'

interface Props {
    currentUser: User | null
}

const AdminPortal = ({ currentUser }: Props) => {
    const { tab } = useParams<{ tab: string }>()
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState<string>(tab || 'reports')
    const [isAdmin, setIsAdmin] = useState<boolean | null>(null)
    const [userChecked, setUserChecked] = useState(false)

    useEffect(() => {
        if (currentUser !== null) {
            setUserChecked(true)
            setIsAdmin(currentUser.attributes?.is_admin || false)
        }
    }, [currentUser])

    useEffect(() => {
        if (userChecked && isAdmin) {
            setSelectedTab(tab || 'reports')
        }
    }, [userChecked, isAdmin, tab])

    const handleTabChange = (
        event: React.SyntheticEvent | null,
        newValue: string | number | null
    ) => {
        if (typeof newValue === 'string') {
            setSelectedTab(newValue)
            navigate(newValue === 'reports' ? '/admin' : `/admin/${newValue}`)
        }
    }

    if (!userChecked || isAdmin === null) {
        return <Sheet>Loading...</Sheet>
    }

    if (userChecked && (!currentUser || !currentUser.attributes?.is_admin)) {
        return <Navigate to="/" />
    }

    return (
        <Container>
            <Box sx={{ width: '100%', marginTop: 4 }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <TabList>
                        <Tab value="reports">Reports</Tab>
                    </TabList>
                    <TabPanel value="reports">
                        <AdminReports
                            isAdmin={isAdmin}
                            currentUser={currentUser}
                        />
                    </TabPanel>
                </Tabs>
            </Box>
        </Container>
    )
}

export default AdminPortal
