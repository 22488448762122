import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import api from 'config/axiosConfig';
import { Comment, User } from 'util/constants';
import CommentInput from 'components/Comments/CommentInput';
import RenderComments from 'components/Comments/RenderComments';

interface Props {
  currentUser: User;
  commentableType: Comment['commentableType'];
  commentableId: Comment['commentableId'];
}

const CommentsSection: React.FC<Props> = ({ currentUser, commentableType, commentableId }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await api.get(`/api/comments/${commentableType}/${commentableId}`);
        setComments(response.data);
        setLoading(false); 
      } catch (error) {
        console.error('Error fetching comments:', error);
        toast.error('Failed to load comments');
        setLoading(false);
      }
    };

    fetchComments();
  }, [commentableType, commentableId]);

  const addNewComment = async (content: string) => {
    try {
      const response = await api.post(`/api/comments/${commentableType}/${commentableId}`, {
        content,
      });
      let newComment = response.data
      await api.post(`/api/votes/comment/${newComment.id}`, {
          user_id: currentUser.id,
          rel_type: 'user_upvote',
      });
      newComment = await api.get(`/api/comments/${newComment.id}`)
      setComments([newComment.data, ...comments]);
      toast.success('Comment added successfully');
    } catch (error) {
      console.error('Error adding new comment:', error);
      toast.error('Failed to add comment');
    }
  };

  const addReply = async (parent_id: string, content: string) => {
    try {
      const response = await api.post(`/api/comments/${commentableType}/${commentableId}`, {
        content,
        parent_id: parent_id
      });
      let newComment = response.data
      await api.post(`/api/votes/comment/${newComment.id}`, {
          user_id: currentUser.id,
          rel_type: 'user_upvote',
      });
      newComment = await api.get(`/api/comments/${newComment.id}`)
      const updatedComments = comments.map(comment => comment.id === parent_id ? {...comment, replies: [...comment.replies, newComment.data]} : comment);
      setComments(updatedComments);
      toast.success('Reply added successfully');
    } catch (error) {
      console.error('Error adding reply:', error);
      toast.error('Failed to add reply');
    }
  };

  const updateComment = async (commentId: string, updatedContent: string) => {
    try {
      const response = await api.put(`/api/comments/${commentId}`, {
        content: updatedContent
      });
      const updatedComments = comments.map(comment => comment.id === commentId ? response.data : comment);
      setComments(updatedComments);
      toast.success('Comment updated successfully');
    } catch (error) {
      console.error('Error updating comment:', error);
      toast.error('Failed to update comment');
    }
  };

  const deleteCommentById = async (commentId: string) => {
    try {
      const response = await api.delete(`/api/comments/${commentId}`);
      const updatedComments = comments.map(comment => comment.id === commentId ? response.data : comment);
      setComments(updatedComments);
      toast.success('Comment deleted successfully');
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    }
  };
  
  return (
    <div>
      {<CommentInput
        onSubmit={addNewComment}
        placeholder="Add a comment"
        buttonText="Comment"
      />}
      {loading && (
        Array.from({ length: 5 }).map((_, index) => (
          <div
            className="relative mb-4 h-20 w-full animate-pulse rounded bg-gray-300 dark:bg-gray-800"
            key={index}
          ></div>
        )))}
        {comments && (
        <>
          <RenderComments
            comments={comments}
            maxDepth={30}
            isAllCollapsed={true}
            onAddReply={addReply}
            onUpdateComment={updateComment}
            onDeleteComment={deleteCommentById}
            currentUser={currentUser}
            commentableType={commentableType}
            commentableId={commentableId}
          />
        </>
      )}
    </div>
  );
};

export default CommentsSection;