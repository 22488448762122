import React, { useState, useEffect } from 'react'
import { Box, Typography, Card, CircularProgress, Grid } from '@mui/joy'
import api from 'config/axiosConfig'
import EnemyCard from 'components/Enemies/EnemyCard'
import ImpactCard from 'components/Impacts/ImpactCard'
import SolutionCard from 'components/Solutions/SolutionCard'
import { User } from 'util/constants'

interface Props {
    username: string
    currentUser: User
}
//fix: use imports from util/constants; struggling right now with the case/switching
interface Entity {
    id: string
    eid: string
    entity_type: 'enemy' | 'impact' | 'solution'
    attributes: any
    created_ts: string
    updated_ts: string
    type: 'entity'
    uniqueKey: string
}

interface Comment {
    id: string
    content: string
    created_ts: string
    updated_ts: string
    type: 'comment'
    uniqueKey: string
}

type ContentItem = Entity | Comment

const UserProfileOverview = ({ username, currentUser }: Props) => {
    const [content, setContent] = useState<ContentItem[]>([])
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(true)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)

    const fetchUserOverview = async (username: string, page: number) => {
        setLoading(true)
        try {
            const [entitiesResponse, commentsResponse] = await Promise.all([
                api.get<Entity[]>('/api/entities', {
                    params: {
                        username,
                        limit: 3,
                        offset: (page - 1) * 3,
                    },
                }),
                api.get<Comment[]>('/api/comments', {
                    params: {
                        username,
                        limit: 3,
                        offset: (page - 1) * 3,
                    },
                }),
            ])

            const newEntities = entitiesResponse.data.map((entity) => ({
                ...entity,
                type: 'entity' as const,
                uniqueKey: `entity-${entity.eid}`,
            }))
            const newComments = commentsResponse.data.map((comment) => ({
                ...comment,
                type: 'comment' as const,
                uniqueKey: `comment-${comment.id}`,
            }))

            const combinedContent = [...newEntities, ...newComments]

            combinedContent.sort(
                (a, b) =>
                    new Date(b.created_ts).getTime() -
                    new Date(a.created_ts).getTime()
            )

            setContent((prevContent) => [...prevContent, ...combinedContent])

            if (combinedContent.length === 0) {
                setHasMore(false)
            }
        } catch (error) {
            setError('Error fetching user overview')
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUserOverview(username, page)
    }, [username, page])

    useEffect(() => {
        const handleScroll = () => {
            if (loading || !hasMore) return

            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 100
            ) {
                setPage((prevPage) => prevPage + 1)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [loading, hasMore])

    const renderItemCard = (item: ContentItem) => {
        if (item.type === 'entity') {
            switch (item.entity_type) {
                case 'enemy':
                    return (
                        <EnemyCard
                            enemy={item.attributes}
                            showMeta={true}
                            showActions={true}
                            truncateDescription={true}
                            isEnemyProfilePage={false}
                            currentUser={currentUser}
                        />
                    )
                case 'impact':
                    return (
                        <ImpactCard
                            impact={item.attributes}
                            showMeta={true}
                            showActions={true}
                            truncateDescription={true}
                            isImpactProfilePage={false}
                            currentUser={currentUser}
                        />
                    )
                case 'solution':
                    return (
                        <SolutionCard
                            solution={item.attributes}
                            showMeta={true}
                            showActions={true}
                            truncateDescription={true}
                            isSolutionProfilePage={false}
                            currentUser={currentUser}
                        />
                    )
                default:
                    return null
            }
        } else if (item.type === 'comment') {
            return (
                <Card variant="outlined" sx={{ p: 2 }}>
                    <Typography sx={{ mb: 1 }}>{username}</Typography>
                    <Typography sx={{ mb: 2 }}>{item.content}</Typography>
                    <Typography
                        sx={{ textAlign: 'right', color: 'neutral.500' }}
                    >
                        {new Date(item.updated_ts).toLocaleString()}
                    </Typography>
                </Card>
            )
        }
    }

    if (loading && page === 1) {
        return <CircularProgress />
    }

    if (error) {
        return <Typography color="danger">{error}</Typography>
    }

    return (
        <Box sx={{ my: 2 }}>
            <Box sx={{ mb: 2 }}>
                <Typography>Sort by: New</Typography>
            </Box>

            <Grid container spacing={1}>
                {content.map((item) => (
                    <Grid xs={12} key={item.uniqueKey}>
                        {renderItemCard(item)}
                    </Grid>
                ))}
            </Grid>

            {loading && <CircularProgress />}
        </Box>
    )
}

export default UserProfileOverview
