import React, { useEffect, useRef, useState } from 'react'
import Vote from './Vote'
import Save from './Save'
import { Chip, Menu, MenuItem } from '@mui/joy'
import './EntityActions.css'
import { Enemy, Impact, Solution, User, EntityType, Comment } from 'util/constants'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import ShareIcon from '@mui/icons-material/Share'
import LinkIcon from '@mui/icons-material/Link'

interface Props {
    entity: Enemy | Solution | Impact | Comment
    entityType: EntityType
    onVote?: () => void
    currentUser: User
    onSave?: () => void
}

const EntityActions = ({
    entity,
    entityType,
    onVote,
    currentUser,
    onSave,
}: Props) => {
    // const [showShareOptions, setShowShareOptions] = useState(false)
    const [copied, setCopied] = useState(false)
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const shareIconRef = useRef<HTMLButtonElement>(null)
    const menuRef = useRef<HTMLDivElement>(null)
    const entityId = entityType === 'comment' 
    ? (entity as Comment).id?.toString() 
    : entityType === 'enemy' 
        ? (entity as Enemy).name?.replace(/ /g, '_') 
        : (entity as Solution | Impact).id.toString()
    const url = `${window.location.origin}/${entityType}/${entityId}`

    const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null) // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget) // Open the menu
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          const element = event.target as HTMLElement;
          if (
            anchorEl &&
            !shareIconRef.current?.contains(element) &&
            !menuRef.current?.contains(element)
          ) {
            handleMenuClose();
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);

    const handleShare = () => {
        navigator.clipboard.writeText(url).then(() => {
            handleMenuClose()
            setAnchorEl(null)
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
        })
    }

    const handleComment = () => {
        if (window.location.href === `${url}/comments`) {
            const commentsSection =
                document.getElementsByClassName('comment-title')[0]
            if (commentsSection) {
                commentsSection.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                })
            }
        } else {
            window.location.href = `${url}/comments`
        }
    }

    const capitalizeFirstLetter = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }

    const getChipColor = (
        entityType: EntityType,
        entity: Enemy | Solution | Impact | Comment
    ) => {
        if (entityType === 'impact') {
            return (entity as Impact).rel_type === 'impact_positive' ? '#419357' : '#df7023'
        }
        switch (entityType) {
            case 'enemy':
                return '#cf1f1f'
            case 'solution':
                return '#005286'
            default:
                return '#555555' // Fallback color
        }
    }

    return (
        <div className={`${entityType !== 'comment' ? 'entity-actions flex items-center mt-2 md:mt-0 md:flex-col ' : 'entity-actions flex items-center mt-2 md:mt-0 md:flex-row'}`}>
            {(entityType !== 'comment' && <Chip
                component={'div' as any}
                label={capitalizeFirstLetter(entityType)}
                color="neutral"
                variant="soft"
                size="lg"
                sx={{
                    backgroundColor: getChipColor(entityType, entity),
                    color: '#fff',
                    fontSize: '0.75rem',
                    paddingY: '0.44rem',
                }}
            >
                    {capitalizeFirstLetter(entityType)}
                </Chip>
            )}
            <Vote
                entityId={entity.id}
                onVote={onVote}
                voteCount={entity.vote_count}
                entityType={entityType}
                currentUser={currentUser}
            />
            <div className="action comment flex text-xl grow">
                {entityType !== 'comment' &&
                <button
                    className="size-9 rounded-3xl bg-gray-200 flex justify-center items-center hover:bg-gray-300 transition-colors"
                    onClick={handleComment}
                >
                    <ChatBubbleOutlineIcon />
                </button>
                }
            </div>
            {entityType !== 'comment' && (
                <>
            <div className="action save flex text-xl relative mx-2 md:my-2">
                <Save
                    entityId={entity.id.toString()}
                    entityType={entityType}
                    currentUser={currentUser}
                    onSave={onSave}
                />
            </div>

            <div className="action share text-xl relative">
                <button
                    className="size-9 rounded-3xl bg-gray-200 flex justify-center items-center hover:bg-gray-300 transition-colors"
                    onClick={handleMenuToggle}
                    ref={shareIconRef}
                >
                    <ShareIcon />
                </button>

                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    ref={menuRef}
                >
                    <MenuItem onClick={handleShare}>
                        <LinkIcon />
                    </MenuItem>
                </Menu>
                {copied && (
                    <div className="copied-banner text-sm absolute">
                        Copied!
                    </div>
                )}
            </div>
            </>
            )}
        </div>
    )
}

export default EntityActions
