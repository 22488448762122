import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MainPage from 'components/MainPage'
import AuthLogout from 'components/Auth/AuthLogout'
import AddEnemy from 'components/Enemies/AddEnemy'
import EnemyProfile from 'components/Enemies/EnemyProfile'
import EnemiesList from 'components/Enemies/EnemiesList'
import UserProfile from 'components/User/UserProfile'
import SolutionProfile from 'components/Solutions/SolutionProfile'
import ImpactProfile from 'components/Impacts/ImpactProfile'
import Header from 'components/Header'
import UserSettings from 'components/User/UserSettings'
import AdminPortal from 'components/Admin/AdminPortal'
import api from 'config/axiosConfig'
import AuthModal from 'components/Auth/AuthModal'
import AuthLogin from 'components/Auth/AuthLogin'
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { defaultUser, User } from 'util/constants'

const materialTheme = materialExtendTheme()

const App = () => {
    const [user, setUser] = useState<User>(defaultUser)
    const [awaitingUser, setAwaitingUser] = useState(true)
    const [showAuthModal, setShowAuthModal] = useState(false)

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get('/api/user')
                setUser(response.data)
                setShowAuthModal(false)
            } catch (error) {
                console.error('Error fetching user:', error)
            } finally {
                setAwaitingUser(false)
            }
        }

        fetchUser()
    }, [])

    return (
        <Router>
            <MaterialCssVarsProvider
                theme={{ [MATERIAL_THEME_ID]: materialTheme }}
            >
                <JoyCssVarsProvider>
                    <CssBaseline enableColorScheme />
                    <div className="App h-full">
                        <Header
                            currentUser={user}
                            showAuthModal={setShowAuthModal}
                        />
                        <Routes>
                            <Route
                                path="/"
                                element={<MainPage currentUser={user} />}
                            />
                            <Route
                                path="/:sort"
                                element={<MainPage currentUser={user} />}
                            />
                            <Route path="/logout" element={<AuthLogout />} />
                            <Route
                                path="/login"
                                element={
                                    <AuthLogin
                                        awaitingUser={awaitingUser}
                                        currentUser={user}
                                    />
                                }
                            />
                            <Route
                                path="/add-enemy"
                                element={
                                    <AddEnemy
                                        currentUser={user}
                                        open={false}
                                        onClose={() => {}}
                                    />
                                }
                            />
                            <Route
                                path="/enemy/:name/"
                                element={
                                    <EnemyProfile
                                        currentUser={user}
                                        setShowAuthModal={() => {}}
                                    />
                                }
                            />
                            <Route
                                path="/enemy/:name/:tab"
                                element={
                                    <EnemyProfile
                                        currentUser={user}
                                        setShowAuthModal={setShowAuthModal}
                                    />
                                }
                            />
                            <Route
                                path="/enemies"
                                element={
                                    <EnemiesList
                                        currentUser={user}
                                        sort="new"
                                    />
                                }
                            />
                            <Route
                                path="/settings/"
                                element={
                                    <UserSettings
                                        currentUser={user}
                                        awaitingUser={awaitingUser}
                                    />
                                }
                            />
                            <Route
                                path="/settings/:tab"
                                element={
                                    <UserSettings
                                        currentUser={user}
                                        awaitingUser={awaitingUser}
                                    />
                                }
                            />
                            <Route
                                path="/user"
                                element={<UserProfile currentUser={user} />}
                            />
                            <Route
                                path="/user/:username"
                                element={<UserProfile currentUser={user} />}
                            />
                            <Route
                                path="/user/:username/:tab"
                                element={<UserProfile currentUser={user} />}
                            />
                            <Route
                                path="/solution/:id"
                                element={<SolutionProfile currentUser={user} />}
                            />
                            <Route
                                path="/impact/:id"
                                element={<ImpactProfile currentUser={user} />}
                            />
                            <Route
                                path="/admin"
                                element={<AdminPortal currentUser={user} />}
                            />
                            <Route
                                path="/admin/:tab"
                                element={<AdminPortal currentUser={user} />}
                            />
                        </Routes>
                        {showAuthModal && (
                            <AuthModal
                                show={showAuthModal}
                                onClose={() => setShowAuthModal(false)}
                            />
                        )}
                    </div>
                </JoyCssVarsProvider>
            </MaterialCssVarsProvider>
        </Router>
    )
}

export default App
