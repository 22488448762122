import React, { useState, useEffect } from 'react'
import api from 'config/axiosConfig'
import AuthModal from 'components/Auth/AuthModal'
import { useNavigate } from 'react-router-dom'
import {
    Textarea,
    Button,
    Input,
    CircularProgress,
    FormLabel,
    FormControl,
} from '@mui/joy'

import { User, Solution } from 'util/constants'

interface Props {
    currentUser: User | null
    solution: Solution
    description: string
    referenceLink: string
    editMode?: boolean
}

const SolutionEditor = ({
    currentUser,
    solution,
    description,
    referenceLink,
    editMode = true,
}: Props) => {
    const navigate = useNavigate()
    const [newDescription, setDescription] = useState<string>(description)
    const [newReferenceLink, setReferenceLink] = useState<string>(referenceLink)
    const [showAuthModal, setShowAuthModal] = useState<boolean>(false)
    const [authorized, setAuthorized] = useState<boolean>(true)

    useEffect(() => {
        if (!currentUser) {
            setAuthorized(false)
            setShowAuthModal(true)
        } else if (currentUser.id !== solution.user_id) {
            setAuthorized(false)
            navigate(`/solution/${solution.id}`)
            window.location.reload()
        }
    }, [currentUser, solution, navigate])

    if (!authorized) {
        return null // Prevent rendering if not authorized
    }

    const handleReferenceLinkChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setReferenceLink(e.target.value)
    }

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDescription(e.target.value)
    }

    const handleSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        try {
            await api.patch(`/api/solutions/id/${solution.id}`, {
                description: newDescription,
                reference_link: newReferenceLink,
            })
            navigate(`/solution/${solution.id}`)
            window.location.reload()
        } catch (error) {
            console.error('Error saving solution:', error)
        }
    }

    const handleCancel = () => {
        navigate(`/solution/${solution.id}`)
        window.location.reload()
    }

    if (!solution) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="card-margin">
            <div>
                {solution && (
                    <div className="edit-solution-card">
                        <h3 className="solution-title">
                            {solution.id} {solution.name}
                        </h3>
                        <FormControl required>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                minRows={8}
                                maxRows={1024}
                                defaultValue={description}
                                onChange={handleDescriptionChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Reference Link</FormLabel>
                            <Input
                                type="url"
                                defaultValue={referenceLink}
                                onChange={handleReferenceLinkChange}
                                fullWidth
                            />
                        </FormControl>
                        <div className="edit-actions">
                            <Button
                                variant="outlined"
                                color="neutral"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="solid"
                                color="primary"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </div>
                        {solution.reference_link && (
                            <a
                                href={solution.reference_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="solution-card-link"
                            >
                                {solution.reference_link}
                            </a>
                        )}
                    </div>
                )}
            </div>
            {showAuthModal && (
                <AuthModal
                    show={showAuthModal}
                    onClose={() => setShowAuthModal(false)}
                />
            )}
        </div>
    )
}

export default SolutionEditor
