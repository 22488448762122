import React from 'react';
import Comment from './Comment';
import { Comment as CommentData, User, Entity } from 'util/constants';

interface RenderCommentsProps {
  comments: CommentData[];
  depth?: number;
  maxDepth: number;
  isAllCollapsed: boolean;
  onAddReply: (parentId: string, content: string) => void;
  onUpdateComment: (id: string, content: string) => void;
  onDeleteComment: (id: string) => void;
  currentUser: User;
  commentableType: CommentData['commentableType'];
  commentableId: CommentData['commentableId'];
  entity?: Entity;
}

const RenderComments: React.FC<RenderCommentsProps> = ({
  comments,
  depth = 0,
  maxDepth = 30,
  isAllCollapsed,
  onAddReply,
  onUpdateComment,
  onDeleteComment,
  currentUser,
  commentableType,
  commentableId,
}) => {
  return (
    comments.map((comment) => (
        <Comment
          key={comment.id}
          {...comment}
          depth={depth}
          maxDepth={maxDepth}
          isAllCollapsed={isAllCollapsed}
          onAddReply={onAddReply}
          onUpdateComment={onUpdateComment}
          onDeleteComment={onDeleteComment}
          commentableType={commentableType}
          commentableId={commentableId}
          currentUser={currentUser}>
        </Comment>
    ))
  );
};

export default RenderComments;