import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import EntityActions from 'components/Entities/EntityActions'
import Report from 'components/Entities/Report'
import { Menu, Chip } from '@mui/joy'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import 'components/Entities/EntityCard.css'
import { Enemy, User } from 'util/constants'
import EnemyThumbnail from './EnemyThumbnail'

interface Props {
    enemy: Enemy
    showMeta: boolean
    truncateDescription: boolean
    isEnemyProfilePage?: boolean
    currentUser: User
    showActions?: boolean
    onVote?: () => void
}

interface WikiSummary {
    thumbnail?: {
        source: string
        width: number
        height: number
    }
    originalimage?: {
        source: string
        width: number
        height: number
    }
    extract: string | null
}

const EnemyCard = ({
    enemy,
    showMeta = true,
    truncateDescription = true,
    isEnemyProfilePage = false,
    currentUser,
}: Props) => {
    const navigate = useNavigate()
    const [isExpanded, setIsExpanded] = useState(false)
    const enemyHref = `/enemy/${encodeURIComponent(enemy.name.replace(/ /g, '_'))}`
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const moreIconRef = useRef<HTMLButtonElement>(null)
    const menuRef = useRef<HTMLDivElement>(null);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false)
    const reportModalRef = useRef<HTMLDivElement>(null)
    const [imageUrl, setImageUrl] = React.useState<string | null>(null)
    const [extract, setExtract] = React.useState<string | null>(null)

    useEffect(() => {
        const fetchThumbnail = async () => {
            try {
                const response = await fetch(
                    `https://en.wikipedia.org/api/rest_v1/page/summary/${enemy.name}`
                )
                const data: WikiSummary = await response.json()
                const imageSource =
                    data.thumbnail?.source || data.originalimage?.source || null
                setImageUrl(imageSource)
                setExtract(data.extract)
            } catch (error) {
                console.error('Error fetching Wikipedia summary:', error)
            }
        }

        fetchThumbnail()
    }, [enemy.name, extract])

    const truncateText = (text: string, length: number) => {
        if (!text || text.length <= length) return text
        return text.substring(0, length) + '...'
    }

    const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const element = event.target as HTMLElement
        if (element.tagName.toLowerCase() === 'a') return
        navigate(`${enemyHref}`)
    }

    const handleDescriptionClick = (event: React.MouseEvent) => {
        if (!truncateDescription) return
        event.preventDefault()
        if (isEnemyProfilePage) {
            setIsExpanded(!isExpanded)
        } else {
            return
        }
    }

    const handleMenuToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null) // Close the menu if it's already open
        } else {
            setAnchorEl(event.currentTarget) // Open the menu
        }
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        setIsReportModalOpen(false)
    }

    // Close the menu if clicked outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          const element = event.target as HTMLElement;
          if (
            anchorEl &&
            !moreIconRef.current?.contains(element) &&
            !menuRef.current?.contains(element) &&
            !reportModalRef.current?.contains(element)
          ) {
            handleMenuClose();
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [anchorEl]);

    return (
        <div className="card-container divide-y divide-gray-300">
            <article>
                <div className="md:rounded-lg flex flex-col hover:bg-gray-100 p-4 md:my-1 cursor-pointer md:flex-row flex-wrap relative">
                    <div className="entity-actions-container order-last md:order-first md:w-20">
                        <EntityActions
                            entity={enemy}
                            entityType="enemy"
                            currentUser={currentUser}
                        />
                    </div>
                    <div className="card-content order-first md:order-last md:ml-4">
                        {showMeta && (
                            <div className="card-meta text-sm flex items-center relative">
                                <Link
                                    to={`/user/${enemy.username}`}
                                    className="mr-2 font-semibold text-gray-600 hover:text-gray-500 transition-colors"
                                >
                                    {enemy.username}
                                </Link>
                                <span className="grow">
                                    {new Date(
                                        enemy.updated_ts
                                    ).toLocaleString()}
                                </span>
                                <div className="icon-more text-xl">
                                    <button
                                        className="size-9 rounded-3xl bg-gray-200 flex justify-center items-center hover:bg-gray-300 transition-colors"
                                        onClick={handleMenuToggle}
                                        ref={moreIconRef} 
                                    >
                                        <MoreHorizOutlinedIcon />
                                    </button>
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                    ref={menuRef}
                                >
                                    <Report
                                        entityId={enemy.id.toString()}
                                        entityType="enemy"
                                        currentUser={currentUser}
                                        isOpen={isReportModalOpen}
                                        setIsOpen={setIsReportModalOpen}
                                        modalRef={reportModalRef}
                                    />
                                </Menu>
                            </div>
                        )}
                        <div
                            className="card-header text-xl font-semibold text-gray-600 my-2"
                            onClick={handleCardClick}
                        >
                            <Link to={enemyHref} className="card-title">
                                {enemy.name}
                            </Link>
                            <div className="card-tags flex flex-row gap-2">
                                {enemy.properties?.status && (
                                    <Chip
                                    component={'div' as any}
                                    label={enemy.properties.status}
                                    color="neutral"
                                    variant="soft"
                                    size="lg"
                                    sx={{
                                        backgroundColor: '#cf1f1f',
                                        color: '#fff',
                                        fontSize: '0.75rem',
                                        paddingY: '0.44rem',
                                    }}
                                >
                                        {enemy.properties.status}
                                    </Chip>
                                )}
                                {enemy.properties?.type && (
                                    <Chip
                                    component={'div' as any}
                                    label={enemy.properties.type}
                                    color="neutral"
                                    variant="soft"
                                    size="lg"
                                    sx={{
                                        backgroundColor: '#555555',
                                        color: '#fff',
                                        fontSize: '0.75rem',
                                        paddingY: '0.44rem',
                                    }}
                                >
                                        {enemy.properties.type}
                                    </Chip>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col-reverse md:flex-row gap-2">
                            <div
                                className="card-description "
                                onClick={handleCardClick}
                            >
                                <span onClick={handleDescriptionClick}>
                                    {truncateDescription && !isExpanded
                                        ? truncateText(
                                              extract ||
                                                  '',
                                              512
                                          )
                                        : extract}
                                </span>
                            </div>
                            <EnemyThumbnail imageUrl={imageUrl} />
                        </div>
                        <a
                            href={enemy.wikipedia_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="card-link block text-xs py-4 lg:pt-0"
                        >
                            {truncateText(enemy.wikipedia_link || '', 64)}
                        </a>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default EnemyCard
