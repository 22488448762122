import React, { useState, useEffect } from 'react'
import { Box, Card, Typography, CircularProgress } from '@mui/joy'
import api from 'config/axiosConfig'
import { User, Comment } from 'util/constants'

interface Props {
    username: string
    currentUser: User
}

const UserProfileComments = ({ username, currentUser }: Props) => {
    const [comments, setComments] = useState<Comment[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [page, setPage] = useState<number>(1)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)
    const [initialLoadDone, setInitialLoadDone] = useState<boolean>(false)

    const fetchUserComments = async (username: string, page: number) => {
        try {
            const response = await api.get<Comment[]>('/api/comments', {
                params: {
                    username,
                    limit: 5,
                    offset: (page - 1) * 5,
                },
            })

            if (response.data.length < 5) {
                setHasMore(false)
            }

            setComments((prevComments) => {
                const newComments = response.data.filter(
                    (newComment) =>
                        !prevComments.some(
                            (comment) => comment.eid === newComment.eid
                        )
                )
                return [...prevComments, ...newComments]
            })

            setLoading(false)
        } catch (err) {
            setError('Failed to load comments')
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchUserComments(username, page)
    }, [username, page])

    useEffect(() => {
        const handleScroll = () => {
            if (loading || !hasMore) return

            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight
            ) {
                setPage((prevPage) => prevPage + 1)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [loading, hasMore])

    useEffect(() => {
        if (
            !initialLoadDone &&
            !loading &&
            hasMore &&
            document.documentElement.scrollHeight <= window.innerHeight
        ) {
            setInitialLoadDone(true)
            setPage((prevPage) => prevPage + 1)
        }
    }, [loading, hasMore, initialLoadDone])

    if (loading && page === 1) {
        return <CircularProgress />
    }

    if (error) {
        return <Typography color="danger">{error}</Typography>
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pb: 4 }}>
            {comments.map((comment) => (
                <Card key={comment.eid} variant="outlined" sx={{ p: 2 }}>
                    <Typography sx={{ mb: 1 }}>{username}</Typography>
                    <Typography sx={{ mb: 2 }}>{comment.content}</Typography>
                    <Typography
                        sx={{ textAlign: 'right', color: 'neutral.500' }}
                    >
                        {new Date(comment.updated_ts).toLocaleString()}
                    </Typography>
                </Card>
            ))}
            {loading && (
                <CircularProgress sx={{ alignSelf: 'center', mt: 2 }} />
            )}
        </Box>
    )
}

export default UserProfileComments
